.sidebarContent {
    overflow-x: auto;
    height: 100%;
    > div {
        height: 100%;
        > div:last-child {
            height: 95%;
        }
    }
}

.pageContainer {
    min-width: 710px;
    max-width: 1260px;
    margin: auto;
}
