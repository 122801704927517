.permissionRow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.permissionCol {
  width: 30%;
}
.dragItem {
  width: 250px;
  border: 1px solid black;
  /* padding: 10px;
  margin: 10px; */
}
.userSelect {
  width: 250px;
  margin-bottom: 20px;
}
/* .droppableContainer {
  width: 400px;
  margin-bottom: 20px;
} */
.list {
  height: 500px;
  overflow: auto;
}

.createButton {
  margin-bottom: 20px;
}

.groupsContainer {
  padding: 20px;
  border: 1px solid hsl(225, 17%, 95%);
}

.arrow {
  display: block;
  cursor: pointer;
  color: #f12;
}
