$sidebarWidth: 300px;

.mapboxglPopup {
  max-width: 400px;
  padding: 10px;
  font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  background-color: white;
}

.mapContainer {
  height: 100%;
  width: 65%;
  margin-right: 20px;
}

.managerTrackerMapContainer {
  width: 70%;
}
.managerLocationMapContainer {
  width: 80%;
}

.managerMapContainer {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 83vh;
}

.pageContainer {
  min-width: 0px;
  max-width: 100%;
  margin: 0px;
}

.managerMapFilter {
  margin-right: 20px;

  > div {
    margin-bottom: 10px;
  }

  > .tableFilterContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 525px;

    > .filter {
      width: 240px;
      margin-bottom: 10px;
    }
    > * {
      margin-right: 20px;
    }
  }

  > Button {
    margin: 5px;
    width: 240px;
  }

}

.selectedRowStyle {
  background-color: #fafafa;
}

.ant-table-tbody > .clickRowStyl:hover > td {
  background-color: #fafafa;
  cursor: pointer;
}

.rowHoverPointer {
  cursor: pointer;
}

.managerTrackerRedRow {
  cursor: pointer;
  background: #ff6666 !important;
  > td{
    background: #ff6666 !important;
  }
}

.managerTrackerCell {
  width: 525px;
  > div > div > div > div > div > table > tbody > tr > td {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.clientCell {
  width: 287px;
  > div > div > div > div > div > table > tbody > tr > td {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.managerLocationCell {
  width: 360px;
  > div > div > div > div > div > table > tbody > tr > td {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

// .merkerContainer {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: auto;
// }

// .markerImg {
//   border: none;
//   border-radius: 50%;
//   padding: 0;
// }

// .markerTitle {
//   padding: 5;
// }
