.topContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.productInfoContainer {
  display: flex;
  height: 100px;
  flex-direction: row;

  > div {
    width: 180px;
    margin-top: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    > * {
      margin-top: 10px;
      margin-right: 10px;
      margin-bottom: 0px;
    }
  }
}

.modifyProductsContainer {
  display: flex;
  flex-direction: row;

  button {
    margin-right: 10px;
  }
}

.ordersContainer {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.orderList {
  border: 1px solid hsl(225, 17%, 95%);
  height: 800px;
  padding: 10px;
  overflow: auto;
}

.listDivider {
  margin-left: 10px;
  margin-right: 10px;
}

.orderItem {
  margin-top: 20px !important;
  border: 2px solid #00000033 !important;
}

.orderHeader {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .orderStatus:last-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.orderRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > div {
    display: flex;
    flex-direction: column;
  }
  > div:first-child {
    text-align: start;
  }

  > div:last-child {
    justify-content: start;
    text-align: end;
  }
}

.orderActionContainer {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-around !important;
  align-self: center !important;
  text-align: center !important;
}

.paidLess{font-weight: bold; color: red}
.paidMore{color: green}

.editButton {
  margin-top: 10px;
  margin-right: 10px;
}

/* modal */

.productContainer {
  overflow: auto;
  min-height: 64px;
  max-height: 250px;
}

.productItemContainer {
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
}

.productInfo {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.mapContainer {
  margin-top: 60px;
}

.selectedRowStyle {
  background-color: #fafafa;
}

.ant-table-tbody > .clickRowStyl:hover > td {
  background-color: #fafafa;
  cursor: pointer;
}

.rowHoverPointer {
  cursor: pointer;
}

.filterContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10px;

  > div {
    margin: 0px 10px 10px 10px;
  }

  .dateFilter {
    width: 130px;
  }

  .filter {
    width: 240px;
  }
}

.permissionGroupContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  background-color: #fafafa;
  padding: 5px;

  > label {
    margin: 10px;
  }

  .permissionGroup {
    display: flex;
    margin-left: 40px;
    flex-wrap: wrap;
    justify-items: stretch;

    > label {
      width: 240px;
      margin-top: 5px;
    }
  }
}

.permissionList {
  width: 620px;
}

.permissionFilter {
  margin-bottom: 10px;
  display: flex;
  > * {
    margin-right: 10px;
  }
  > *:last-child {
    margin-right: 0;
  }
}

.addPermission {
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-left: transparent;
  width: 37px;
  height: 32px;
  padding: 5px;
  margin: 0;
  text-align: center;
  cursor: pointer;
}

.permissionDropdown {
  width: 200px;
  margin-right: 0;
}
.permissionDropdownContainer {
  display: flex;
}

.groupName {
  display: none;
  margin: 0;
}

.notificationText {
  white-space: pre-line;
  margin-bottom: 10px;
}

.addProductContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;

  .dropdownContainer {
    .filter {
      width: 320px;
    }
    > *:first-child {
      margin-right: 10px;
    }
    margin-bottom: 10px;
  }

  .buttonContainer {
    > * {
      margin-right: 10px;
    }
  }
}

.saleContainer {
  margin: 10px 0 10px 0;

  > * {
    margin-left: 20px;
  }
}

.postmanProductTotalContainer {
  margin-bottom: 10px;
  text-align: end;
  display: flex;
  flex-direction: column;
}

@media print {
  .groupName {
    display: block;
    margin: 10px;
  }
  .notificationText {
    font-size: 22px;
    margin-left: 50px;
    margin-top: 20px;
  }
}
