aside > div:first-child {
  overflow-y: scroll;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

@media print {
  :global(.tableClass) {
    margin: 20px;
    div > div > div > div:first-child {
      border: 1px solid #000 !important;
    }
    div > div > div > div > div:first-child {
      border: none !important;
    }

    div > div > div > div:nth-child(2) {
      border: 1px solid #000 !important;
      border-top: none !important;
    }

    div > div > div > div > div > table > thead > tr > th {
      border-bottom: 1px solid #000 !important;
      border-right: 1px solid #000 !important;
      background-color: transparent !important;
    }
    div > div > div > div > div > table > thead > tr > th:last-child {
      border-bottom: 1px solid #000 !important;
      border-right: none !important;
    }
    div > div > div > div > div > table > tbody > tr > td {
      border-bottom: 1px solid #000 !important;
      border-right: 1px solid #000 !important;
    }
    div > div > div > div > div > table > tbody > tr > td:last-child {
      border-bottom: 1px solid #000 !important;
      border-right: none !important;
    }
    div > div > div > div > div > table > tbody > tr:last-child > td {
      border-bottom: none !important;
    }

    div > div > div > div > div > table > tfoot > tr > td {
      border-top: 1px solid #000 !important;
      border-bottom: 1px solid #000 !important;
    }

    div > div > div > div > div > table > tfoot > tr > td:last-child {
      border-top: 1px solid #000 !important;
      border-right: none !important;
    }
    div > div > div > div > div > table > tbody > tr:last-child > td {
      border-bottom: none !important;
    }
  }

  :global(.rowClass-light) {
    td {
      border: 1px solid #000 !important;
    }

    th {
      border: 1px solid #000 !important;
    }
  }
}
