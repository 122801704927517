
.container {
    margin-left: 20px;
}

.inputContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
}

.input {
    width: 85px;
    text-align: center;
}

.inputSpliter {
    width: 30px; 
    border-left: 0;
    border-right: 0;
    pointer-events: none;
}

.confirmButton {
    margin-left: 20px;
}
