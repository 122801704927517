// Expenses Component

.filterContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  width: 905px;
}

.expenseContainer {
  display: flex;
}

@media only screen and (max-width: 1150px) {
  .expenseContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .tableContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .filterContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

// Add Expense Component

.addExpenseCard {
  margin-top: 16px;
  margin-right: 15px;
  max-width: 340px;
  min-width: 340px;
  width: 340px;
  height: 570px;
  .addExpenseButton {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 1150px) {
  .addExpenseCard {
    margin-top: 16px;
    margin-right: 15px;
    max-width: 650px;
    min-width: 650px;
    width: 650px;
    height: 425px;
    .formContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      & > div {
        margin-left: 20px;
      }
      & > div:first-child {
        width: 250px;
        margin-left: 0px;
      }
      & > div:nth-child(2) {
        width: 300px;
      }
    }
  }
}
