.listContainer {
  border-radius: 15px !important;
  width: 945px !important;
  margin: auto !important;
  margin-bottom: 20px !important;
  padding: 10px !important;
}

.step {
  border-radius: 10px !important;
  margin-top: 10px !important;
  cursor: pointer;
}

.stepCompleted {
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb !important;
  border-radius: 10px !important;
  margin-top: 10px !important;
  text-decoration: line-through;
  cursor: pointer;
}
