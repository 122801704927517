.inputContainer {
  display: flex;
  margin: 10px 0 10px 0;
  > * {
    margin-left: 20px;
  }
}

.confirmButton {
  margin-top: 27px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 5px;
  }
}