.container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.contentItem {
    width: 360px;
    margin-left: 40px;
}

.mapContainer {
    width: 760px;
    height: 360px;
    margin-top: 10px;
}

.limitInfo {
    width: 360px;
    margin-left: 40px;
    align-self: flex-start;
}

@media only screen and (max-width: 992px) {
    .contentItem {
      margin-left: 0;
      margin-top: 20px;
    }
    .limitInfo {
        margin-left: 0;
        margin-top: 20px;
    }
    .container {
        flex-direction: column;
        align-items: flex-start;
    }
    .mapContainer {
        width: 720px;
    }
}
  