.tableContainerRow {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 225px;
}

.selectedRowStyle {
  background-color: #fafafa;
}
.ant-table-tbody > .clickRowStyl:hover > td {
  background-color: #fafafa;
  cursor: pointer;
}

.rowHoverPointer {
  cursor: pointer;
}

.statisticsCharts,
.statisticsTables {
  width: 945px;
  margin-top: 20px;
}

.totalsCard {
  width: 945px;
}

.postmanTableHeader {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  .statisticsCharts,
  .statisticsTables .totalsCard {
    width: 100%;
  }
}
