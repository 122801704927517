.newCountInput,
.rowMargin {
  margin-bottom: 10px;
}

.buttonStyle {
  font-size: 10px;
}

.newCountInput > input,
.newPriceInput > input {
  text-align: right;
}

.productContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  > div {
    margin-left: 20px;
  }
  > div:first-child {
    margin-left: 0px;
  }
  > .productHistory {
    min-width: 680px;
    max-width: 680px;
    width: 680px;
  }
}

.productsContainer {
  width: 945px;
  margin-top: 10px;
}

.totalsHeader {
  width: 945px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}


.filterContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10px;
  > div {
    margin: 0px 10px 10px 10px;
  }

  .dateFilter {
    width: 130px;
  }

  .filter {
    width: 240px;
  }
}

.productListItemContainer {
  display: flex;
  margin-top: 10px;
  > div {
    flex-direction: column;
    margin-left: 20px;
    margin-top: 10px;
  }
  > div:first-child {
    margin-left: 0px;
    align-self: center;
  }
  > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  > .productDescriptionContainer {
    width: 150px;
  }

  .productModifiersContainer > div {
    margin-top: 10px;}
}

.productCheckboxContainer {
  display: flex;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 1000px) {
  .productContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    > div {
      margin-left: 20px;
    }
    > div:first-child {
      margin-left: 0px;
    }
    > .productHistory {
      min-width: 680px;
      max-width: 680px;
      width: 680px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .productsContainer {
    width: 100%;
  }
}

.productHistoryTextContainer {
  display: flex;
  span {
    white-space: nowrap;
    padding-left: 5px;
    padding-right: 5px;
  }
  span:first-of-type{
    flex: 1;
    text-align: right;
    padding-left: 0px;
    padding-right: 0px;
  }
  span:last-of-type{
    text-align: right;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.ant-card-body {
  padding: 0;
}
