.uploadContainer {
  margin-bottom: 20px;
}
.userList {
  display: flex;
  flex-direction: column;
}
.createBtn {
  width: 170px;
}

.debtBtn {
  width: 170px;
  margin-right: 20px;
}
.roleSearch {
  display: flex;
  flex-direction: row;
  width: 340px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.search {
  width: 340px;
  margin-bottom: 20px;
}
.role {
  width: 200px;
}
.hvhhSearch {
  width: 340px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.blockFilter {
  width: 160px;
}

/* permissions */
.permissions {
  width: 100%;
}

/* create user */
.container {
  display: flex;
  flex-direction: row;
}
.createUserForm {
  width: 900px;
}
.formItems {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.formRow {
  width: 300px;
}
.generateBtn {
  float: right;
}
.mapContainer {
  width: 300px;
  height: 300px;
  background-color: violet;
}
.orderChart {
  margin-top: 30px;
}

.debtContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  // width: 450px;
  margin-bottom: 6px;
  .filterContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    // height: 100px;
    // width: 640px;
    > .filter {
      margin: 5px;
      width: 300px;
    }
    > .filter:first-child {
      margin-left: 0px;
    }
  }

  .printBtn {
    width: 100px;
    margin: 5px;
    justify-self: flex-end;
  }
}

.managerList {
  width: 300px;
}

.cellFloatRight {
  /* text-align: end; */
  font-weight: bold;
}

.userEditUploadImage {
  margin-bottom: 20px;
}

.userEditFormContainer{
  display: flex;
  > div {
    width: 360px;
  }
  > div:first-child {
    margin-right: 50px;
  }
}

.userEditFormItem {
  width: 100px;
}