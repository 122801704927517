.ordersContainer {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.orderList {
  border: 1px solid hsl(225, 17%, 95%);
  height: 800px;
  padding: 10px;
  overflow: auto;
}

.listDivider {
  margin-left: 10px;
  margin-right: 10px;
}

.orderItem {
  margin-top: 20px;
  background-color: white;
}

.orderHeader {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.orderContent {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.userInfo {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;
}

.footer {
  /* marginTop: 10 */
}

.moreInfo {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.editButton {
  margin-top: 10px;
  margin-right: 10px;
}

/* modal */
.productContainer {
  overflow: auto;
  min-height: 64px;
  max-height: 250px;
}

.productItemContainer {
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
}

.productInfo {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.mapContainer {
  margin-top: 60px;
}

.orderContainer {
  display: flex;
  flex-direction: column;

  > div {
    margin-top: 10px;
  }
}

.orderFilterContainer {
  display: flex;
  align-items: flex-start;
  > * {
    margin-right: 10px;
  }
}

.rowHoverPointer {
  cursor: pointer;
}

.orderPostmanChangeContainer {
  display: flex;
  flex-direction: column;
}

.addProductSpan {
  font-size: 16px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.onHand {
  font-size: 16px;
  font-weight: bold;
}

.postmanInfo {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.deliveredOrdersTotals {
  display: flex;
  justify-content: flex-end;
  > div {
    display: flex;
    flex-direction: column;
  }
}

.postman {
  display: none;
  margin: 0;
}

.popupTextArea {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  > div:last-child {
    width: 100% !important;
  }
}

.orderInfoContainer {
  display: none;
}

.dateFilter {
  width: 130px;
}

@media print {
  .postman {
    display: block;
    margin: 10px;
  }

  .orderPostmanChangeContainer {
    margin-left: 20px;
  }

  .addProductSpan {
    font-size: 22px;
    margin-left: 50px;
    margin-bottom: 0;
  }

  .onHand {
    font-size: 22px;
    font-weight: bold;
    margin-top: 5px;
  }

  .postmanInfo {
    display: flex;
    justify-content: space-around;
    font-size: 22px;
    margin: 20px;
  }

  .orderInfoContainer {
    display: flex;
    flex-direction: column;
    padding: 20px;
    font-size: 20px;
    font-weight: bold;
  }

  .headerContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    > div > div {
      margin-bottom: 5px;
    }
    border-bottom: 1px solid rgb(167, 166, 166);
    margin-bottom: 10px;
  }

  .footerContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    > div > div {
      margin-bottom: 5px;
    }
    > div:last-child {
      text-align: end;
    }
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .clientInfoContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
}
