.makeOrderContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  > div {
    width: 480px;
    min-width: 480px;
    max-width: 480px;
    margin-left: 20px;
  }
  > div:first-child {
    margin: 0px;
  }
}

.approvePopupContainer {
  display: flex;
  flex-direction: column;
  & > span {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.popupTextArea {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  > div:last-child {
    width: 100% !important;
  }
}
