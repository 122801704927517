.chart {
  padding: 20px;
  margin-top: 30px;
}

.chartWithShadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.filterContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10px;
  > div {
    margin: 0px 10px 10px 10px;
  }

  .dateFilter {
    width: 130px;
  }

  .filter {
    width: 240px;
  }

  .productFilter {
    width: 320px;
  }
}

.dataContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > div {
    margin-bottom: 20px;
    // width: 945px;
  }
}

@media only screen and (max-width: 1200px) {
  .dataContainer {
    > div {
      width: 100%;
    }
  }
}
